import * as React from 'react';

import { Container, Wrapper } from './HomePage.styles';
import {
  Footer,
  FooterLink,
  FooterLinksDesktop,
  FooterLinkMobileContact,
} from './Footer.styles';
import {
  GetSupportSecondaryTitle,
  GetSupportSecondary,
} from './GetSupportSecondary.styles';
import {
  Process,
  ProcessList,
  ProcessListItem,
  ProcessListItemDescription,
  ProcessListItemIndex,
  ProcessListItemTitle,
  ProcessListItemContent,
  ProcessTitle,
  ActionButton,
} from './Process.styles';
import {
  Introduction,
  IntroductionDescription,
  IntroductionLeftSection,
  IntroductionQrCodeDescription,
  IntroductionQrCodeTitle,
  IntroductionQrCodeWrapper,
  IntroductionTitle,
  CompanyList,
  CompanyItem,
} from './Introduction.styles';
import {
  GetSupportMain,
  GetSupportMainDescription,
  GetSupportMainDescriptionLong,
  GetSupportMainTitle,
  GetSupportMainTitleLong,
} from './GetSupportMain.styles';
import { Header, HeaderLogo, LogoWrapper } from './Header.styles';
import BakerLogo from './BakerLogo';
import { ApLogo } from './Images';

export const HomePage = () => {
  return (
    <Container>
      <Header>
        <HeaderLogo>
          <LogoWrapper>
            <BakerLogo />
          </LogoWrapper>
          <LogoWrapper>
            <ApLogo />
          </LogoWrapper>
        </HeaderLogo>
      </Header>

      <GetSupportMain>
        <GetSupportMainTitle>Help for the Santiam Fires</GetSupportMainTitle>
        <GetSupportMainTitleLong>
          Help <br />
          for the Santiam Fires
        </GetSupportMainTitleLong>
        <GetSupportMainDescription>
          Get in touch if you were affected by the 2020 smoke damage to the
          Willamette Valley wine industry.
        </GetSupportMainDescription>
        <GetSupportMainDescriptionLong>
          Get in touch if you were affected by the 2020 smoke damage to the
          Willamette Valley wine industry.
        </GetSupportMainDescriptionLong>
        <a
          href="https://powerforms.docusign.net/0d66857c-06a5-4aed-aead-078187e4e719?env=na2&acct=331b5d[%E2%80%A6]a19e41e92e&accountId=331b5dc1-f602-4da5-a1ac-efa19e41e92e"
          target="_blank"
          rel="noreferrer"
        >
          <ActionButton>
            Sign up
          </ActionButton>
        </a>
      </GetSupportMain>

      <Introduction>
        <IntroductionLeftSection>
          <IntroductionTitle>Benefit from our expertise</IntroductionTitle>
          <IntroductionDescription>
            If your winery or vineyard was damaged by smoke from the 2020
            Santiam Fires, and you wish to be represented by a highly capable
            legal team, you can sign up here.
          </IntroductionDescription>
          <CompanyList>
            <CompanyItem>
              <a
                href="https://www.bakerlaw.com/"
                target="_blank"
                rel="noreferrer"
              >
                BakerHostetler LLP
              </a>
            </CompanyItem>
            <CompanyItem>
              <a
                href="https://wattsguerra.com/"
                target="_blank"
                rel="noreferrer"
              >
                Watts Guerra LLC
              </a>
            </CompanyItem>
            <CompanyItem>
              <a
                href="https://www.arnoldgallagher.com/"
                target="_blank"
                rel="noreferrer"
              >
                Arnold Gallagher P.C.
              </a>
            </CompanyItem>
          </CompanyList>
        </IntroductionLeftSection>
        <IntroductionQrCodeWrapper>
          <IntroductionQrCodeTitle>
            Open webiste on mobile
          </IntroductionQrCodeTitle>
          <IntroductionQrCodeDescription>
            Scan this QR code with your device camera to open
          </IntroductionQrCodeDescription>
        </IntroductionQrCodeWrapper>
      </Introduction>

      <Process>
        <ProcessTitle>4 simple steps</ProcessTitle>
        <ProcessList>
          <ProcessListItem>
            <ProcessListItemIndex>1</ProcessListItemIndex>
            <ProcessListItemContent>
              <ProcessListItemTitle>
                Click the “Sign up” button
              </ProcessListItemTitle>
              <ProcessListItemDescription></ProcessListItemDescription>
            </ProcessListItemContent>
          </ProcessListItem>

          <ProcessListItem>
            <ProcessListItemIndex>2</ProcessListItemIndex>
            <ProcessListItemContent>
              <ProcessListItemTitle>
                Enter your information
              </ProcessListItemTitle>
              <ProcessListItemDescription>
                Read carefully and sign your Engagement Letter, and then enter
                the financial information relating to damages.
              </ProcessListItemDescription>
            </ProcessListItemContent>
          </ProcessListItem>

          <ProcessListItem>
            <ProcessListItemIndex>3</ProcessListItemIndex>
            <ProcessListItemContent>
              <ProcessListItemTitle>
                Hit the “Finish” button
              </ProcessListItemTitle>
              <ProcessListItemDescription>
                Once your signature and information are entered, please submit.
              </ProcessListItemDescription>
            </ProcessListItemContent>
          </ProcessListItem>

          <ProcessListItem>
            <ProcessListItemIndex>4</ProcessListItemIndex>
            <ProcessListItemContent>
              <ProcessListItemTitle>We will be in touch!</ProcessListItemTitle>
              <ProcessListItemDescription>
                We will review your information and reach out!
              </ProcessListItemDescription>
            </ProcessListItemContent>
          </ProcessListItem>
        </ProcessList>
      </Process>

      <GetSupportSecondary>
        <Wrapper>
          <GetSupportSecondaryTitle>
            Get smoke
            <br /> relief support
          </GetSupportSecondaryTitle>
        </Wrapper>
        <a
          href="https://powerforms.docusign.net/0d66857c-06a5-4aed-aead-078187e4e719?env=na2&acct=331b5d[%E2%80%A6]a19e41e92e&accountId=331b5dc1-f602-4da5-a1ac-efa19e41e92e"
          target="_blank"
          rel="noreferrer"
        >
          <ActionButton>
            Sign up
          </ActionButton>
        </a>
      </GetSupportSecondary>

      <Footer>
        <LogoWrapper>
          <ApLogo />
        </LogoWrapper>
        <FooterLinksDesktop>
          <FooterLink>
            <a
              href="mailto:santiam@bakerlaw.com"
              target="_blank"
              rel="noreferrer"
            >
              Contact: santiam@bakerlaw.com
            </a>
          </FooterLink>

          <FooterLink>
            <a
              href="https://www.docusign.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </FooterLink>
        </FooterLinksDesktop>

        <FooterLinkMobileContact>
          <FooterLink>
            <a
              href="mailto:santiam@bakerlaw.com"
              target="_blank"
              rel="noreferrer"
            >
              Contact: santiam@bakerlaw.com
            </a>
          </FooterLink>
          <FooterLink>
            <a
              href="https://www.docusign.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </FooterLink>
        </FooterLinkMobileContact>
      </Footer>
    </Container>
  );
};

export default HomePage;
