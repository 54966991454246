import styled from 'styled-components';
import headerBg from '../../assets/images/Gradient.png';
import { screenSizes } from '../../utils/theme';

export const GetSupportSecondary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding: 50px 16px 64px 16px;
  gap: 62px;
  isolation: isolate;
  height: 288px;
  a {
    width: 100%;
  }

  position: relative;
  &::after {
    background: url(${headerBg}) no-repeat center #eee;
    background-size: cover;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  @media screen and (min-width: ${screenSizes.xxl}) {
    flex-direction: row;
    align-items: center;
    padding: 64px 212px 64px 64px;
    a {
      max-width: 185px;
    }
  }
`;

export const GetSupportSecondaryTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 33px;
  line-height: 40px;
  color: #fafafa;

  @media screen and (min-width: ${screenSizes.xxl}) {
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
  }
`;

export const GetSupportSecondaryDescription = styled.div`
  display: none;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  display: flex;
  align-items: flex-end;
  color: #ffffff;
  margin-right: 64px;
  margin-top: 16px;
  margin-bottom: 38px;

  @media screen and (min-width: ${screenSizes.xxl}) {
    display: block;
  }
`;
