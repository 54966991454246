/**
 * A class built with the specific purpose of looking after the telemetry of all applications withing AP
 */
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

let appInsights: any = null;

/**
 * The first setup that must be called in order for AppInsights to work
 *
 * @param {string} instrumentationKey - the key used to setup app insights
 * @param {react node} history - an instance of the react history
 */
const setupTelemetry = (
  instrumentationKey: string | undefined | null,
  history: any
) => {
  if (!instrumentationKey || !history) return;

  var reactPlugin = new ReactPlugin();
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: instrumentationKey,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: history },
      },
    },
  });
  // return the app insights instance
  return appInsights.loadAppInsights();
};

/**
 * Used to track events on the application
 *
 * @param {string} eventName - the name of the event you want to track, this can be any string
 * @param {object} eventProps - any extra information to be sent to application insights
 */
const trackEvent = (eventName: string, eventProps: any = {}) => {
  // If the insights setup has yet to be completed reject for now
  if (!eventName || !appInsights) return;

  appInsights.trackEvent({
    name: eventName,
    properties: eventProps,
  });
};

/**
 * Export Telemetry as a single object for easier recognition
 */
export const Telemetry = {
  setup: setupTelemetry,
  trackEvent: trackEvent,
};
