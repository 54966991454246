import styled from 'styled-components';

export const MainButton = styled.a`
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 60px;
  background: #ffffff;
  color: #1a1a1a;
  border-radius: 1px;
  font-weight: 600;
  font-size: 19px;
  line-height: 20px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  font-family: 'Roboto';
  font-style: normal;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;
