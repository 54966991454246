import styled from 'styled-components';
import apLogoFull from '../../assets/images/ap-logo-full.svg';
import { screenSizes } from '../../utils/theme';

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  padding: 32px 16px;
  justify-content: space-between;

  @media screen and (min-width: ${screenSizes.xxl}) {
    flex-direction: row;
    align-items: center;
    height: 88px;
    padding: 34px 80px;
  }
`;

export const FooterLogo = styled.div`
  width: 162px;
  height: 24px;
  position: relative;
  &::before {
    background: url(${apLogoFull}) no-repeat center;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
`;

export const FooterLinksDesktop = styled.ul`
  display: none;
  justify-content: space-between;
  flex-direction: row;

  @media screen and (min-width: ${screenSizes.xxl}) {
    display: flex;
  }
`;

export const FooterLinksMobile = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: ${screenSizes.xxl}) {
    display: none;
  }
`;

export const FooterLinkMobilePrivacy = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const FooterLinkMobileContact = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;

  @media screen and (min-width: ${screenSizes.xxl}) {
    display: none;
  }
`;

export const FooterLink = styled.li`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #1a1a1a;
  margin-left: 0px;

  @media screen and (min-width: ${screenSizes.xxl}) {
    margin-left: 40px;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const FooterLogoWrapper = styled.div`
  margin-right: 16px;
  height: 16px;
`;
