import styled from 'styled-components';
import { colors, screenSizes } from '../../utils/theme';
import qrCodeBg from '../../assets/images/qr-code-footer.png';

export const Process = styled.div`
  display: flex;
  flex-direction: column;

  padding: 32px 16px;
  background: #ffffff;
  margin-bottom: 16px;

  @media screen and (min-width: ${screenSizes.xxl}) {
    padding: 0 80px;
    background: #f7f7f7;
    padding: 32px 270px;
  }
`;

export const ProcessListItemContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProcessTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #4d4d4d;
  margin-top: 16px;

  @media screen and (min-width: ${screenSizes.xxl}) {
    font-size: 40px;
    line-height: 48px;
  }
`;

export const ProcessDescription = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #333333;
  margin-top: 20px;
  margin-right: 24px;

  @media screen and (min-width: ${screenSizes.xxl}) {
    font-weight: 400;
    font-size: 19px;
    line-height: 24px;
    margin-left: -3px;
    margin-top: 24px;
    max-width: 72%;
  }
`;

export const ProcessList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  @media screen and (min-width: ${screenSizes.xxl}) {
    margin-top: 60px;
  }
`;

export const ProcessListItem = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 40px 16px;
  background: #ffffff;
  box-shadow: 0px 9px 28px rgba(0, 0, 0, 0.25);
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 20px;

  @media screen and (min-width: ${screenSizes.xxl}) {
    padding: 38px 48px;
  }
`;

export const ProcessListItemTitle = styled.div`
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-size: 19px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.2px;
  display: flex;
  align-items: center;
  color: #424242;

  @media screen and (min-width: ${screenSizes.xxl}) {
    font-size: 28px;
    font-weight: 500;
    line-height: 34px;
  }
`;

export const ProcessListItemIndex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin-top: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 24px;
  width: 24px;
  height: 24px;
  background: #ffffff;
  border: 2px solid #424242;
  color: #424242;
  margin-right: 20px;

  @media screen and (min-width: ${screenSizes.xxl}) {
    width: 40px;
    min-width: 40px;
    height: 40px;
    font-size: 33px;
    font-weight: 700;
    line-height: 40px;
    margin-right: 40px;
  }
`;

export const ProcessListItemDescription = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  flex-direction: column;
  color: #424242;
  margin: 20px 0;
  a {
    color: #424242;
  }
  @media screen and (min-width: ${screenSizes.xxl}) {
    font-size: 19px;
    line-height: 24px;
  }
  
`;

export const ProcessItemInternalLists = styled.div`
  display: flex;
  align-items: space-between;
`;

export const ProcessItemInternalList = styled.ul`
  list-style: none;
  width: 49%;
  &:last-child {
    margin-left: 1%;
  }
`;

export const ProcessItemInternalListItem = styled.li`
  list-style: none;
  display: flex;
  margin-bottom: 12px;
  line-height: 20px;
`;

export const ProcessItemInternalListItemText = styled.span`
  margin-left: 10px;
`;

export const ListTickWrapper = styled.div`
  height: 100%;
  width: 16px;
`;

export const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  padding: 18px 60px;
  background-color: #FFFFFF;
  font-size: 19px;
  font-weight: 600;
  line-height: 20px;
  color: ${colors.grey800};
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  a {
    color: ${colors.grey800};
  }

  &:hover {
    background-color: rgba(255,255,255,0.12);
    color: #FAFAFA;
    a {
      color: #FAFAFA;
    }
  }

  @media screen and (min-width: ${screenSizes.xxl}) {
    width: auto;
  }
`;

export const MobileContainer = styled.div`
  height: 646px;
  display: none;
  flex-direction: column;
  align-items: center;
  padding: 80px;

  @media screen and (min-width: ${screenSizes.xxl}) {
    display: flex;
  }
`;

export const MobileTitle = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: ${colors.grey700};
  line-height: 34px;
`;
 export const MobileDescription = styled.div`
  font-size: 16px;
  color: ${colors.grey600};
  line-height: 20px;
  margin-top: 8px;
  margin-bottom: 48px;
 `;
export const MobileQrCode = styled.div`
  width: 350px;
  height: 350px;
  background: url(${qrCodeBg}) no-repeat center #FFFFFF;
`;