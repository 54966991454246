import styled from 'styled-components';
import headerBg from '../../assets/images/Gradient.png';
import headerBgMobile from '../../assets/images/GradientMobile.png';
import { screenSizes } from '../../utils/theme';

export const GetSupportMain = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 16px;
  isolation: isolate;
  height: 553px;
  max-height: calc(100vh - 72px);
  position: relative;
  a {
    width: 100%;
  }

  &::after {
    background: url(${headerBgMobile}) no-repeat center #eee;
    background-size: cover;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  @media screen and (min-width: ${screenSizes.xxl}) {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 135px 80px 80px 80px;
    height: 545px;
    a {
      max-width: 185px;
    }

    &::after {
      background: url(${headerBg}) no-repeat center #eee;
      background-size: cover;
    }
  }
`;

export const GetSupportMainTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  color: #ffffff;
  max-width: 600px;

  @media screen and (min-width: ${screenSizes.xxl}) {
    display: none;
  }
`;

export const GetSupportMainTitleLong = styled(GetSupportMainTitle)`
  display: none;
  letter-spacing: 0.9px;
  height: auto;

  @media screen and (min-width: ${screenSizes.xxl}) {
    font-size: 64px;
    display: block;
    line-height: 76px;
    max-width: 800px;
  }
`;

export const GetSupportMainDescription = styled.div`
  display: none;
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
  max-width: 600px;

  @media screen and (min-width: ${screenSizes.xxl}) {
    display: block;
    margin-top: 16px;
    margin-bottom: 40px;
    font-size: 19px;
    line-height: 28px;
  }
`;

export const GetSupportMainDescriptionLong = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 32px;
  margin-bottom: 40px;
  max-width: 700px;

  @media screen and (min-width: ${screenSizes.xxl}) {
    display: none;
  }
`;
