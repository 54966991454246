import styled from 'styled-components';
import qrCodeBg from '../../assets/images/frame.png';
import { screenSizes } from '../../utils/theme';

export const IntroductionTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #303030;

  @media screen and (min-width: ${screenSizes.xxl}) {
    font-size: 40px;
    line-height: 48px;
  }
`;

export const IntroductionDescription = styled.div`
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #424242;
  margin-right: 27px;
  max-width: 600px;

  @media screen and (min-width: ${screenSizes.xxl}) {
    font-size: 19px;
    line-height: 24px;
  }
`;

export const IntroductionQrCodeWrapper = styled.div`
  min-height: 270px;
  position: relative;
  margin-top: 4px;

  &::after {
    background: url(${qrCodeBg}) no-repeat center;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: -16px;
    bottom: 0;
  }
`;

export const IntroductionQrCodeTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  margin-top: 224px;
`;

export const IntroductionQrCodeDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 16px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #4d4d4d;
`;

export const IntroductionLeftSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Introduction = styled.div`
  display: flex;
  flex-direction: column;

  padding: 32px 16px 66px;
  background: #f7f7f7;

  ${IntroductionQrCodeWrapper} {
    display: none;
  }

  @media screen and (min-width: ${screenSizes.xxl}) {
    padding: 90px 40px 80px 80px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    background: #ffffff;

    ${IntroductionQrCodeWrapper} {
      display: block;
      margin-right: 160px;
      &::after {
        top: -108px;
      }
    }

    ${IntroductionLeftSection} {
      width: 46%;
    }

    ${IntroductionQrCodeTitle} {
      margin-top: 210px;
    }
    ${IntroductionQrCodeDescription} {
      max-width: 240px;
      font-size: 16px;
      line-height: 20px;
      margin: 8px 60px 0;
    }

    ${IntroductionDescription} {
      font-size: 19px;
      line-height: 28px;
      margin-top: 32px;
    }
  }
`;

export const CompanyList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  @media screen and (min-width: ${screenSizes.xxl}) {
    flex-direction: row;
  }
`;

export const CompanyItem = styled.div`
  font-size: 12px;
  line-height: 32px;
  color: #3f51b5;
  margin-right: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  a:hover {
    color: #303030;
  }
`;
