import styled from 'styled-components';
import { screenSizes } from '../../utils/theme';

export const HeaderLogo = styled.div`
  width: 274px;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;

  @media screen and (min-width: ${screenSizes.xxl}) {
    width: 483px;
    height: 36px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 72px;
  padding: 16px;

  @media screen and (min-width: ${screenSizes.xxl}) {
    padding: 16px 80px;
    justify-content: flex-start;
    button {
      font-size: 23px;
      height: 34px;
    }
  }
`;

export const LogoWrapper = styled.div`
  margin-right: 32px;
  height: 20px;
  display: flex;
  align-items: center;
`;
