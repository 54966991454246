export const colors = {
  green1: '#00FF00',
  green2: '#00CC00',
  green3: '#009900',
  green4: '#006600',
  green5: '#003300',
  green6: '#000000',
  green7: '#000000',
  grey600: '#4D4D4D',
  grey700: '#333333',
  grey800: '#1A1A1A',
};

export const scaffolding = {
  xs: '4px',
  sm: '8px',
  md: '16px',
  lg: '32px',
  xl: '64px',
};

export const screenSizes = {
  xxs: '320px',
  xs: '480px',
  sm: '600px',
  md: '768px',
  lg: '900px',
  xl: '1024px',
  xxl: '1000px',
};
